import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Styles
import { Container } from "../styles/globalStyles"
import {
  FeatureSection,
  FeatureSectionInner,
  FeatureContent,
  FeatureText,
  FeatureTextInner,
  FeatureImage,
} from "../styles/featureStyles"

const Feature = ({ name, title, description, image, inverted }) => {
  return (
    <FeatureSection>
      <Container>
        <FeatureSectionInner>
          <FeatureContent inverted={inverted}>
            <FeatureText>
              <FeatureTextInner>
                <h5>{name}</h5>
                <h1>{title}</h1>
                <FeatureImage>
                  <img width="359" height="201" src={image.file.url} alt="" />
                </FeatureImage>
                <div>{renderRichText(description)}</div>
              </FeatureTextInner>
            </FeatureText>
            <FeatureImage>
              <img width="359" height="201" src={image.file.url} alt="" />
            </FeatureImage>
          </FeatureContent>
        </FeatureSectionInner>
      </Container>
    </FeatureSection>
  )
}

export default Feature
