import styled, { css } from "styled-components"
import { Grid } from "./globalStyles"

export const FeatureSection = styled.section`
  padding-block: var(--space-m);

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    height: min(100vh, 40rem);
    display: grid;
    place-content: center;
  }
`

export const FeatureSectionInner = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    ${Grid}
  }
`

export const FeatureText = styled.div`
  ul {
    list-style: none;

    li:before {
      content: "";
      height: 1.55555em;
      width: 1.55555em;
      display: block;
      float: left;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      margin-inline-end: var(--space-s);
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path><polyline points='22 4 12 14.01 9 11.01'></polyline></svg>");
    }

    p {
      margin-bottom: var(--space-s);
    }
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    flex: 0 1 50%;
    padding-inline: var(--space-p);
  }
`

export const FeatureImage = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  > img {
    width: min(400px, 100%);
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    flex: 0 1 50%;
    padding-inline: var(--space-p);
  }
`

export const FeatureTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-n);

  h1 {
    margin-bottom: 0;
  }

  /* Adjustments */
  @media (max-width: 1199px) {
    & > ${FeatureImage} {
      display: flex;
    }
  }
`

export const FeatureContent = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    padding-inline: var(--space-n);
    grid-column: 2 / span 4;
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    display: flex;
    gap: var(--space-s);
    padding-inline: 0;
    grid-column: 3 / span 8;

    ${({ inverted }) =>
      inverted &&
      css`
        flex-direction: row-reverse;
      `}

    & > ${FeatureImage} {
      display: flex;
    }
  }
`
